import "core-js";

import $ from "jquery";

require("rem-unit-polyfill/js/rem.js");

// Browsernizr
require("browsernizr/test/svg");
require("browsernizr/test/css/rgba");
require("browsernizr/test/css/flexbox");
require("browsernizr/test/forms/placeholder");
require("browsernizr");

// Language selector
$(function () {
  // language selector dropdown
  $(".form-language .dropdown-toggle").click(function (e) {
    e.preventDefault();
    const $menu = $(this).siblings(".dropdown-menu");

    if ($menu.hasClass("show")) {
      $(document).click();
    } else {
      $menu.addClass("show");

      setTimeout(function () {
        $(document).one("click", function () {
          $menu.removeClass("show");
        });
      }, 0);
    }
  });

  // language selector form submit
  const $formLanguage = $(".form-language");
  const $languageSelect = $formLanguage.find("select");
  $languageSelect.data("defaultVal", $languageSelect.val());

  $languageSelect.on("change blur", function (e) {
    if ($languageSelect.val() !== $languageSelect.data("defaultVal")) {
      $formLanguage.submit();
    }
  });
});

// SSO redirect
$(function () {
  const redirectUrl = $(".sso").data("automatic-redirect-url");
  if (redirectUrl) {
    setTimeout(function () {
      window.location = redirectUrl;
    }, 5000);
  }
});
